
// ==============================


// GENERICOS
// ==============================

body{
  background-color: #dfdfdf;
}



// BOTONES AYUDA REFRESH
// -----------------------------



#log {
  position: fixed;
  top:0;
  left: 0;
  font-size: 1rem;
  margin: 0;
  padding: 0.7em 0.8em;
}


.btn {
  z-index: 100;
  position: fixed;
  font-size: 1rem;
  margin: 0;
  padding: 0.7em 0.9em;

  @include breakpoint(small only)  {
    font-size: 0.7rem;
  }


  &#btn-help {
    top:0;
    right: 0;
  }

  &#btn-refresh {
    bottom:0;
    left: 0;
  }

}





// CLASESS MODALS
// ==============================

.reveal.help {
  color: $white;
  font-size: 1rem;
  padding: 3rem;
  border: 0px;
  border-radius: 1rem;
  background-color: $complementario-color;

  @include breakpoint(small only){
    font-size: 0.7rem;
  }

  table {
    thead {
      tr {
        background-color: $table-modal-help-dark;
        color: $white;
        th {
          text-align: center;
        }
      }
    }
    tbody {
      tr {
        background-color: $table-modal-help-light;
        color: $black;
      }
    }
  } // Table

} // FIN .reveal.help

.reveal.error {
  color: $white;
  font-size: 1.1rem;
  padding: 3rem;
  border: 0px;
  border-radius: 1rem;
  background-color: $alert-color;
}

.close-button {
  color: $white;

}


// TITULO
// ==============================
.titulo {
  //  outline:1px solid blue;
  color: $white;

  ::after {
    background-color: $complementario-color;
    position: fixed;
    z-index: -1;
    width: 100%;
    height: 20%;
    min-height: 100px;
    content: '';
    top: 0;
    left: 0;
  }
} // fin .titulo



// MOVILES
// ==============================


.moviles {
  //  outline:1px solid red;
  //  height: 90vh;
  position: relative;
  min-width: 750px;


  @include breakpoint(small only)  {
    min-width: 320px;
  }



  .column {padding: 0; box-sizing: border-box;}
  .row {margin-left: auto !important; margin-right: auto !important;}
  .end {
    padding-right: 1rem;

    @include breakpoint(small only) {
      padding-right: 0.3rem;
    }

  }


  // CONTENEDOR DE MOVILES
  // -----------------------------
  .iphone {
    position: relative;
    margin: auto;
    height: 720px;
    width: 365px;

    @include breakpoint(small only) {
      height: 305px;
      width: 155px;
      font-size: 0.6rem;
    }

    & .app-mobile {
      position: absolute;
      //  outline:1px solid green;
      top: 111px;
      left: 25px;
      height: 525px;
      width: 313px;


      @include breakpoint(small only) {
        top: 47px;
        left: 11px;
        height: 224px;
        width: 132px;
      }

    }

    &.no-iphone .app-mobile {
      position: absolute;
      //  outline:1px solid green;
      top: 87px;
      left: 20px;
      height: 508px;
      width: 322px;


      @include breakpoint(small only) {
        top: 37px;
        left: 8px;
        height: 216px;
        width: 138px;
      }

    }


    // CONTENEDOR DE CHAT
    // -----------------------------

    .app-mobile {  //    común
      .container {
        min-height: 100%;  /* viewport height en % */
        width: 100%;
        background: url("../img/chat-bg.jpg") center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
      }


      // IDIOMA
      // -----------------------------
      .idioma {
        background: $white;
        width: 100%;
        height: 32px;
        position: relative;

        @include breakpoint(small only) {
          height: 20px;
        }
        .menu > li > a {

          @include breakpoint(small only) {
            padding: 0.45rem 0.3rem;
          }
          &:after {

            @include breakpoint(small only) {
              right: -3px;
            }
          }
        }


        .submenu > li > a {background-color: $white;}
        .submenu > li > a:hover {background-color: $white;}

        &-flag {
          height: 32px;

          @include breakpoint(small only) {
            height: 20px;
          }

        }
      }

      // CHAT
      // -----------------------------
      .chat{
        overflow-x: hidden;
        overflow-y: scroll;
        position: absolute;
        top: 32px;
        bottom: 33px;
        width: 100%;


        @include breakpoint(small only) {
          top: 20px;
          bottom: 16.64px;
        }

        &-empty {
          flex: 1 1 auto;
          min-height: 12px;
        }

        &-list{
          flex: 0 0 auto;
          //          padding-bottom: 8px;
          transition: padding 0.3s cubic-bezier(0.69, 0, 0.79, 0.14);
          padding-left: 0;
          width: 100%;

          &-msg {
            //      outline:1px solid yellow;
            width: 100%;
            height: auto;
            vertical-align: baseline;
            padding-left: 15px;
            padding-right: 15px;
            position: relative;
            margin: 0 0 10px;

            &:after {
              content: '';
              display: table;
              clear: both;
            }

            & span {
              background-color: $bg-msg-color;
              max-width: 95%;
              border-radius: 7.5px;
              float: right;
              padding: 6px 9px;
            }
            &.recibido span{
              background-color: $white;
              float: left;
            }

          }  // fin chat-list-msg




          .bubble-arrow {
            position: absolute;
            width: 0;
            right: 20px;
            bottom:20px;
            height: 0;
            &.recibido{
              left: 5px;
              right: auto;
            }
            &:after {
              content: "";
              position: absolute;
              border: 0 solid transparent;
              border-top: 9px solid $bg-msg-color;
              border-radius: 0 20px 0;
              width: 15px;
              height: 15px;
              //              background-color: brown;
              transform: rotate(45deg) scaleY(-1);
            }
            &.recibido:after {
              transform: rotate(145deg) ;
              border-color: $white;
            }

          }

        } // fin  chat-list


      }


      // ESCRIBIR
      // -----------------------------

      .escribir{
        background-color: $white;
        padding: 0.4rem 1rem;
        position: absolute;
        bottom: 0px;
        height: 33px;
        width: 100%;
        justify-content: space-between;

        @include breakpoint(small only) {
          height: 16.64px;
          padding: 0.2rem 0.5rem;
        }



        input[type="text"] {
          margin: 0;
          width: 88%;
          border-radius: 1.8rem;
          font-size: 1rem;
          height: 1.7rem;
          padding: 2px 10px;

          @include breakpoint(small only) {
            font-size: 0.5rem;
            height: 0.9rem;
            padding: 0px 3px;
          }
        }

        a {
          color: $primary-color;
          font: normal normal normal 1.7rem/1.7rem FontAwesome;
          width: auto;

          @include breakpoint(small only) {
            font: normal normal normal 0.9rem/0.9rem FontAwesome;
          }
        }

      }

    }  // fin .app-mobile - común


  } // fin iphone





} // fin moviles
// FOOTER
// ==============================











